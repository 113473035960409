import { GetServerSideProps, GetServerSidePropsContext } from "next";
import { Chatbot, PublicTypebot } from "@typebot-io/engine";
import { ChatbotPage, ChatbotPageProps } from "../components/ChatbotPage";
import * as Sentry from "@sentry/nextjs";
import {
  findChatbotById,
  findChatbotByCustomDomain,
} from "../services/server/publicTypebot";

export const getServerSideProps: GetServerSideProps = async (
  context: GetServerSidePropsContext
) => {
  let chatbot: Chatbot | PublicTypebot | undefined;
  const pathname = context.resolvedUrl.split("?")[0];
  const isIE = /MSIE|Trident/.test(context.req.headers["user-agent"] ?? "");
  try {
    if (!context.req.headers.host) return { props: {} };
    chatbot = await getChatbotFromUrl(context.req.headers.host, context.query);
    if (!chatbot) return { props: {} };
    return {
      props: {
        typebot: JSON.parse(
          JSON.stringify({
            ...chatbot,
            createdDate: chatbot?.createdDate.toString(),
            modifiedDate: chatbot?.modifiedDate?.toString(),
          })
        ),
        url: `https://${context.req.headers.host}${pathname}`,
        embedHostname: context.query.hn ?? null,
        isIE,
      },
    };
  } catch (err) {
    console.error(err);
    Sentry.captureException(err);
  }
  return {
    props: {
      url: `https://${context.req.headers.host}${pathname}`,
      isIE,
    },
  };
};

const getChatbotFromUrl = async (
  hostname: string,
  query: any
): Promise<Chatbot | PublicTypebot | undefined> => {
  if (hostname.includes("typebot.io")) {
    const chatbotId = hostname.split(".").shift();
    if (!chatbotId) return;
    return findChatbotById(chatbotId, query);
  }
  return findChatbotByCustomDomain(hostname.split(":")[0], query);
};

const App = ({ typebot, url, embedHostname, isIE }: ChatbotPageProps) => (
  <ChatbotPage
    typebot={typebot}
    url={url}
    embedHostname={embedHostname}
    isIE={isIE}
  />
);
export default App;
